export function getElementByParam<T>(list: T[], param: string, value: any): T | null {
    const filtered = list.filter((elem: T) => {
        return elem[param] === value
    })

    if (filtered.length === 0) {
        return null
    }
    return filtered[0]
}

export function getElementByCallback<T>(list: T[], callback: (T) => any, value: any): T | null {
    const filtered = list.filter((elem: T) => {
        return callback(elem) === value
    })

    if (filtered.length === 0) {
        return null
    }
    return filtered[0]
}

/**
 * @return arr without the element
 */
export function removeElementBy<T>(arr: T[], element: T, comparator: string): T[] {
    return arr.filter((e: T) => e[comparator] !== element[comparator])
}

/**
 * @return arr without the element
 */
export function removeElementByCallback<T>(arr: T[], element: T, comparator: (t: T) => any): T[] {
    return arr.filter((e: T) => comparator(e) !== comparator(element))
}

export function removeElementByComparator<T>(arr: T[], element: T, comparator: (t1: T, t2: T) => boolean): T[] {
    return arr.filter((e: T) => !comparator(element, e))
}

export function replaceElementBy<T>(arr: T[], element: T, comparator: string): T[] {
    return arr.map((arrElem: T) => {
        if (element[comparator] === arrElem[comparator]) {
            return element
        }
        return arrElem
    })
}

export function addElementToArrayBy<T>(arr: T[], element: T, comparator: string): T[] {
    let found: boolean = false
    let updatedArray: T[] = arr.map((e: T) => {
        if (e[comparator] === element[comparator]) {
            found = true
            return element
        }
        return e
    })

    if (!found) {
        updatedArray.push(element)
    }

    return updatedArray
}

export function getIntersectionOfArrays<T>(arr1: T[], arr2: T[], comparisonFunction: (elem: T) => any): T[] {
    return arr1.filter((e1: T) => {
        return getElementByCallback(arr2, comparisonFunction, comparisonFunction(e1)) !== null
    })
}

export function inArray<T>(arr: T[], value: T): boolean {
    return arr.indexOf(value) !== -1
}

export function nonUniqueFilter(value, index, self) {
    return self.indexOf(value) !== index;
}