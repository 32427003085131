import {formatDate} from "./date.format.js";


export class DateUtils {
    static addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    static removeDays (date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    static isToday(date){
        return DateUtils.removeDays(new Date(), 1) < date;
    }

    static setTimeToZero(date) {
        const result = new Date(date);
        result.setUTCHours(0, 0, 0, 0);
        return result;
    }

    static setHoursToH(date: Date, h: number) {
        const result = new Date(date);
        result.setUTCHours(h, 0, 0, 0);
        return result;
    }

    static getMondayOfDate(date){
        const day = date.getDay();
        if (day === 1)
            return DateUtils.setTimeToZero(date);

        if (day === 0)
            return DateUtils.setTimeToZero(DateUtils.removeDays(date, 6));

        return DateUtils.setTimeToZero(DateUtils.removeDays(date, day-1));
    }

    static getWeekDaysFromMonday(day){
        let weekDays = [];
        let i;
        let date;
        for (i = 0; i < 5; i++){
            date = new Date(day);
            weekDays.push({
                id: i,
                day: date.setDate(date.getDate() + i)
            });
        }
        return weekDays;
    }

    static dayEquals(day1, day2){
        return this.dateEquals(new Date(day1), new Date(day2));
    }

    static dateEquals(date1: Date, date2: Date){
        return (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate());
    }

    static getDateDisplay(date: Date, format: string)
    {
        return formatDate(date,format);
    }

    /**
     * @param day : string in the shape YYMMDD
     */
    static getDateFromIndexDay(day: string){
        return new Date('20'+day.slice(0,2)+'/'+day.slice(2,4)+'/'+day.slice(4,6));
    }

    static getDayIndexFromDay(day){
        return DateUtils.getDateDisplay(new Date(day), 'ymd');
    }


    static rfc3339(d) {
        function pad(n) {
            return n < 10 ? "0" + n : n;
        }

        function timezoneOffset(offset) {
            let sign;
            if (offset === 0) {
                return "Z";
            }
            sign = (offset > 0) ? "-" : "+";
            offset = Math.abs(offset);
            return sign + pad(Math.floor(offset / 60)) + ":" + pad(offset % 60);
        }

        return d.getFullYear() + "-" +
            pad(d.getMonth() + 1) + "-" +
            pad(d.getDate()) + "T" +
            pad(d.getHours()) + ":" +
            pad(d.getMinutes()) + ":" +
            pad(d.getSeconds()) +
            timezoneOffset(d.getTimezoneOffset());
    }
}
