import {DateUtils} from "../libraries/dateManipulation";

export function getDayColumnDisplay(day: Date): string {
    return DateUtils.getDateDisplay(day, 'd-m');
}

/**
 * @return string in shape of YYYYMMDD
 */
export function getDateToStringIndex(day: Date): string {
    return DateUtils.getDateDisplay(day, 'Ymd');
}