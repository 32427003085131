export function sortBy<T>(a: T[], by: string): T[] {
    let sorted = [...a]

    sorted.sort((a: T, b: T) => {
        if (a[by] < b[by]) {
            return -1
        }
        return 1
    })

    return sorted
}

export function sortByFunction<T>(a: T[], by: (t: T) => any): T[] {
    let sorted = [...a]

    sorted.sort((a: T, b: T) => {
        if (by(a) < by(b)) {
            return -1
        }
        return 1
    })

    return sorted
}