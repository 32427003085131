/* eslint no-extend-native: 0 */

export function formatDate(date:  Date, format: string): string {
    // Defining locale
    //shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const shortMonths = ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'];
    //longMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const longMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    //shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const shortDays = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
    //longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const longDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    // Defining patterns
    const replaceChars = {
        // Day
        d: function () { const d = date.getDate(); return (d < 10 ? '0' : '') + d },
        D: function () { return shortDays[date.getDay()] },
        j: function () { return date.getDate() },
        l: function () { return longDays[date.getDay()] },
        N: function () { const N = date.getDay(); return (N === 0 ? 7 : N) },
        S: function () { const S = date.getDate(); return (S % 10 === 1 && S !== 11 ? 'st' : (S % 10 === 2 && S !== 12 ? 'nd' : (S % 10 === 3 && S !== 13 ? 'rd' : 'th'))) },
        w: function () { return date.getDay() },
        z: function () { const d = new Date(date.getFullYear(), 0, 1); // @ts-ignore
            return Math.ceil((date - d) / 86400000) },
        // Week
        W: function () {
            const target = new Date(date.valueOf())
            const dayNr = (date.getDay() + 6) % 7
            target.setDate(target.getDate() - dayNr + 3)
            const firstThursday = target.valueOf()
            target.setMonth(0, 1)
            if (target.getDay() !== 4) {
                target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7)
            }
            // @ts-ignore
            const retVal = 1 + Math.ceil((firstThursday - target) / 604800000)

            return (retVal < 10 ? '0' + retVal : retVal)
        },
        // Month
        F: function () { return longMonths[date.getMonth()] },
        m: function () { const m = date.getMonth(); return (m < 9 ? '0' : '') + (m + 1) },
        M: function () { return shortMonths[date.getMonth()] },
        n: function () { return date.getMonth() + 1 },
        t: function () {
            let year = date.getFullYear()
            let nextMonth = date.getMonth() + 1
            if (nextMonth === 12) {
                year = year++
                nextMonth = 0
            }
            return new Date(year, nextMonth, 0).getDate()
        },
        // Year
        L: function () { const L = date.getFullYear(); return (L % 400 === 0 || (L % 100 !== 0 && L % 4 === 0)) },
        o: function () { const d = new Date(date.valueOf()); d.setDate(d.getDate() - ((date.getDay() + 6) % 7) + 3); return d.getFullYear() },
        Y: function () { return date.getFullYear() },
        y: function () { return ('' + date.getFullYear()).substr(2) },
        // Time
        a: function () { return date.getHours() < 12 ? 'am' : 'pm' },
        A: function () { return date.getHours() < 12 ? 'AM' : 'PM' },
        B: function () { return Math.floor((((date.getUTCHours() + 1) % 24) + date.getUTCMinutes() / 60 + date.getUTCSeconds() / 3600) * 1000 / 24) },
        g: function () { return date.getHours() % 12 || 12 },
        G: function () { return date.getHours() },
        h: function () { const h = date.getHours(); return ((h % 12 || 12) < 10 ? '0' : '') + (h % 12 || 12) },
        H: function () { const H = date.getHours(); return (H < 10 ? '0' : '') + H },
        i: function () { const i = date.getMinutes(); return (i < 10 ? '0' : '') + i },
        s: function () { const s = date.getSeconds(); return (s < 10 ? '0' : '') + s },
        v: function () { const v = date.getMilliseconds(); return (v < 10 ? '00' : (v < 100 ? '0' : '')) + v },
        // Timezone
        e: function () { return Intl.DateTimeFormat().resolvedOptions().timeZone },
        I: function () {
            let DST: number | null = null
            for (let i = 0; i < 12; ++i) {
                const d = new Date(date.getFullYear(), i, 1)
                const offset = d.getTimezoneOffset()

                if (DST === null) {
                    DST = offset
                }
                else if (offset < DST) { DST = offset; break } else if (offset > DST) break
            }
            // @ts-ignore
            return (date.getTimezoneOffset() === DST) | 0
        },
        O: function () { const O = date.getTimezoneOffset(); return (-O < 0 ? '-' : '+') + (Math.abs(O / 60) < 10 ? '0' : '') + Math.floor(Math.abs(O / 60)) + (Math.abs(O % 60) === 0 ? '00' : ((Math.abs(O % 60) < 10 ? '0' : '')) + (Math.abs(O % 60))) },
        P: function () { const P = date.getTimezoneOffset(); return (-P < 0 ? '-' : '+') + (Math.abs(P / 60) < 10 ? '0' : '') + Math.floor(Math.abs(P / 60)) + ':' + (Math.abs(P % 60) === 0 ? '00' : ((Math.abs(P % 60) < 10 ? '0' : '')) + (Math.abs(P % 60))) },
        T: function () { const tz = date.toLocaleTimeString(navigator.language, {timeZoneName: 'short'}).split(' '); return tz[tz.length - 1] },
        Z: function () { return -date.getTimezoneOffset() * 60 },
        // Full Date/Time
        c: function () { return formatDate(date, 'Y-m-d\\TH:i:sP') },
        r: function () { return date.toString() },
        U: function () { return Math.floor(date.getTime() / 1000) }
    }

    // Simulates PHP's date function
    return format.replace(/(\\?)(.)/g, function (_, esc, chr) {
        return (esc === '' && replaceChars[chr]) ? replaceChars[chr].call(date) : chr
    })
}
