import {useState} from "react";

const useAsyncReducer = (reducer, initialState) => {
    const [state, setState] = useState(initialState);
    const [error, setError] = useState<Error|null>(null)

    const dispatch = async action => {
        const result = reducer(state, action);
        if (typeof result.then === "function") { // if result is async
            try {
                const newState = await result;
                setError(null)
                setState(newState);
            } catch (err) {
                console.log("Issue in the backend update : " + err.message)
                console.log("Pushing back last state : ",state)
                setError(err)
            }
        } else {
            setError(null)
            setState(result);
        }
    };

    return [state, dispatch, error];
};

export default useAsyncReducer;
